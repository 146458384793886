<template>
  <div>
    <table :class="`w-100 table--perweek table__border--${table_style}`">
      <thead :class="`table__head--${table_style}`">
        <th class="table__head--text">Soort</th>
        <th class="table__head--text">Aantal</th>
        <th class="table__head--text">Omzet</th>
        <th class="table__head--text">Winst</th>
        <th class="table__head--text">Winst %</th>
      </thead>
      <tbody>
        <tr
          v-for="(item, key) in table_data"
          :key="key"
          :class="`table__row--color`"
        >
          <td class="table__cell--default">{{ item.soortnaam }}</td>
          <td class="table__cell--default table__data--right">
            {{ item.aantal }}
          </td>
          <td class="table__cell--default table__data--right">
            € {{ item.omzet }}
          </td>
          <td class="table__cell--default table__data--right">
            € {{ item.winst }}
          </td>
          <td class="table__cell--default table__data--right">
            {{ item.perc }}%
          </td>
        </tr>
      </tbody>
      <tfoot class="table__foot">
        <th class="table__foot--padding">Totaal:</th>
        <th class="table__foot--padding table__data--right">
          {{ table_data_sum.aantal }}
        </th>
        <th class="table__foot--padding table__data--right">
          € {{ table_data_sum.omzet }}
        </th>
        <th class="table__foot--padding table__data--right">
          € {{ table_data_sum.winst }}
        </th>
        <th class="table__foot--padding table__data--right"></th>
      </tfoot>
    </table>
  </div>
</template>
  
  <script>
export default {
  props: {
    table_data: [Object, Array],
    table_data_sum: [Object, Array],
    table_style: [String, Number],
  },
};
</script>
<template>
  <div class="m-3 p-2 wrapper wrapper--default">
    <h3 class="text-center">
      Jaaroverzicht details: {{ year }} - {{ country }}
    </h3>
    <hr />
    <Tabs>
      <Tab title="Maanden">
        <Loading v-if="months_loading" />
        <div class="p2" v-else>
          <CountryOverviewDetailsMonthsTable
            :table_style="bu"
            :table_data="country_details_months"
            :table_data_sum="country_details_months_totals"
          />
        </div>
      </Tab>
      <Tab title="Soorten" :onOpen="getSoortData">
        <Loading v-if="soort_loading" />
        <div class="p2" v-else>
          <CountryOverviewDetailsSoortTable
            :table_style="bu"
            :table_data="country_details_soort"
            :table_data_sum="country_details_soort_totals"
          />
        </div>
      </Tab>
      <Tab title="Merken" :onOpen="getMerkData">
        <Loading v-if="merk_loading" />
        <div class="p2" v-else>
          <CountryOverviewDetailsMerkTable
            :table_style="bu"
            :table_data="country_details_merk"
            :table_data_sum="country_details_merk_totals"
          />
        </div>
      </Tab>
      <Tab title="Klanten" :onOpen="getCustomerData">
        <Loading v-if="customer_loading" />
        <div class="p2" v-else>
          <CountryOverviewDetailsCustomerTable
            :table_style="bu"
            :table_data="country_details_customer"
            :table_data_sum="country_details_customer_totals"
          />
        </div>
      </Tab>
    </Tabs>
  </div>
</template>

<script>
import Tabs from "@/components/Tabs/Tabs.vue";
import Tab from "@/components/Tabs/Tab.vue";
import request from "@/functions/request.js";
import Loading from "@/components/Loading.vue";
import CountryOverviewDetailsMonthsTable from "@/components/CountryOverviewDetailsTables/CountryOverviewDetailsMonthsTable.vue";
import CountryOverviewDetailsSoortTable from "@/components/CountryOverviewDetailsTables/CountryOverviewDetailsSoortTable.vue";
import CountryOverviewDetailsMerkTable from "@/components/CountryOverviewDetailsTables/CountryOverviewDetailsMerkTable.vue";
import CountryOverviewDetailsCustomerTable from "@/components/CountryOverviewDetailsTables/CountryOverviewDetailsCustomerTable.vue";

export default {
  props: ["bu", "countryCode", "country", "year"],
  components: {
    Tabs,
    Tab,
    Loading,
    CountryOverviewDetailsMonthsTable,
    CountryOverviewDetailsSoortTable,
    CountryOverviewDetailsMerkTable,
    CountryOverviewDetailsCustomerTable,
  },
  data: () => ({
    country_details_months: null,
    country_details_months_totals: null,
    country_details_soort: null,
    country_details_soort_totals: null,
    country_details_merk: null,
    country_details_merk_details: null,
    country_details_customer: null,
    country_details_customer_totals: null,
    months_loading: true,
    soort_loading: true,
    merk_loading: true,
    customer_loading: true,
  }),
  created() {
    this.getData();
  },
  methods: {
    getData() {
      request(
        `country-details-months/${this.bu}/${this.countryCode}/${this.year}`
      ).then(({ country_details_months, country_details_months_totals }) => {
        this.country_details_months = country_details_months;
        this.country_details_months_totals = country_details_months_totals;
        this.months_loading = false;
      });
    },

    getSoortData() {
      request(
        `country-details-soort/${this.bu}/${this.countryCode}/${this.year}`
      ).then(({ country_details_soort, country_details_soort_totals }) => {
        this.country_details_soort = country_details_soort;
        this.country_details_soort_totals = country_details_soort_totals;
        this.soort_loading = false;
      });
    },

    getMerkData() {
      request(
        `country-details-merk/${this.bu}/${this.countryCode}/${this.year}`
      ).then(({ country_details_merk, country_details_merk_totals }) => {
        this.country_details_merk = country_details_merk;
        this.country_details_merk_totals = country_details_merk_totals;
        this.merk_loading = false;
      });
    },

    getCustomerData() {
      request(
        `country-details-customer/${this.bu}/${this.countryCode}/${this.year}`
      ).then(
        ({ country_details_customer, country_details_customer_totals }) => {
          this.country_details_customer = country_details_customer;
          this.country_details_customer_totals = country_details_customer_totals;
          this.customer_loading = false;
        }
      );
    },
  },
};
</script>